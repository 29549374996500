<template>
  <v-select
    label="label"
    :filterable="false"
    :reduce="(option) => option.value"
    :value="value"
    :options="options"
    :automatic-dropdown="true"
    :dropdownShouldOpen="open"
    @input="input($event)"
    @search="remoteMethod"
  >
    <template slot="no-options"> 検索結果なし </template>
    <template slot="option" slot-scope="option">
      <div class="d-center">
        {{ option.label }}
      </div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div style="font-size: 14px; font-weight: 500" class="selected d-center">
        {{ option.label }}
      </div>
    </template>
  </v-select>
</template>
<script>
import vSelect from "vue-select";
import request from "@/utils/request";
export default {
  props: {
    meta: { type: Object },
    value: { String, Number },
  },
  components: {
    vSelect: vSelect,
  },
  data() {
    return {
      options: [{ label: "入力で検索", value: null }],
      list: [],
      loading: false,
    };
  },
  mounted() {},
  created() {
    request({
      url: this.meta.api,
      method: "get",
    }).then((response) => {
      this.options = response;
      this.options.unshift({ label: "選択無し", value: null });
    });
  },
  methods: {
    open(e) {
      console.log(e.search);
      //return true;
      return e.noDrop ? false : e.open && !e.mutableLoading;
    },
    input(e) {
      console.log(e);
      this.$emit("input", e);
    },
    remoteMethod(query) {
      let vm = this;

      this.loading = true;
      if (query) {
        request({
          url: this.meta.api + (query ? "?query_text=" + query : ""),
          method: "get",
        }).then((response) => {
          vm.options = response;
          vm.options.unshift({ label: "選択無し", value: null });
          vm.list = vm.options;
          setTimeout(() => {
            vm.loading = false;
            // vm.options = vm.list.filter(item => {
            //   return item.label.toLowerCase()
            //     .indexOf(query.toLowerCase()) > -1 || item.value == vm.value
            // })
          }, 200);
        });
      }
    },
  },
};
</script>
<style lang="scss">
.vs__dropdown-menu {
  z-index: 99999 !important;
  top: auto !important;
  bottom: 100% !important;
  border-bottom: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top-color: var(--vs-border-color);
}
.el-input {
  font-size: 14px;
}
.vs__dropdown-toggle {
  height: 38px;
  border-radius: 3px;
  border: 1px solid #dededf;
}
.vs--open .vs__dropdown-toggle {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-color: var(--vs-border-color);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-color: transparent;
}
.open {
  font-family: "Material Icons OutLined";
  content: "\e5c5";
  font-size: 22px;
  color: #707070;
}
</style>
