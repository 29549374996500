/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { getToken } from "@/utils/auth";
import "./assets/style/global.scss";
import Notifications from "vue-notification";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/ja"; // lang i18n
import VueMq from "vue-mq";
import SocialSharing from "vue-social-sharing";
import VueLazyload from "vue-lazyload";
const loadimage = require("./assets/no_image.png");
import VueSocialSharing from "vue-social-sharing";
import {
  PhoneNumberUtil,
  PhoneNumber,
  PhoneNumberFormat,
} from "google-libphonenumber";
Vue.use(VueSocialSharing);
import VueGtm from "vue-gtm";

Vue.use(VueGtm, {
  id: "GTM-NN9JBJ3W", //your
});
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: loadimage,
  loading: loadimage,
  attempt: 1,
});

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.component("fa-icon", FontAwesomeIcon);
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(fas, far, fab);

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity,
  },
});

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-KDSBNNCB7E" },
});

Vue.use(ElementUI, { locale });
// バリデーションパッケージのインストール npm install vee-validate@3 --save
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import moment from "moment";
import * as rules from "vee-validate/dist/rules";
for (let rule in rules) {
  extend(rule, rules[rule]);
}

const adult_check = {
  params: [],
  message: "20歳未満の方は登録できません",
  validate(value) {
    let d = moment(new Date()).add(-20, "y").startOf("month");
    return d.diff(moment(value), "days") > 0;
  },
};
extend("adult_check", adult_check);

extend("phone", {
  message: "{_field_}の形式が正しくありません",
  validate(value) {
    console.log(PhoneNumberUtil);
    const util = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = util.parseAndKeepRawInput(value, "JP");
      return util.isValidNumber(phoneNumber);
    } catch (err) {
      return false;
    }
  },
});

extend("phone_or_email", {
  message: "{_field_}の形式が正しくありません",
  validate(value) {
    console.log(PhoneNumberUtil);
    const util = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = util.parseAndKeepRawInput(value, "JP");
      return util.isValidNumber(phoneNumber);
    } catch (err) {
      if (
        /^[a-zA-Z0-9!$&*.=^`|~#%'+\/?_{}-]+@([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,4}$/.test(
          value
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
});

localize("ja", ja);
localize({ ja });

import LottieAnimation from "lottie-web-vue";
Vue.use(LottieAnimation);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
// バリデーションパッケージここまで

Vue.config.productionTip = false;
if (getToken()) {
  login();
}
Vue.use(Notifications);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

async function login() {
  await store.dispatch("user/getInfo");
}
