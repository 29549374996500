<template>
  <div class="select_wrap">
    <span v-if="list.status">{{ list.errorMessages }}</span>
    <select name="" id="" @change="updateValue">
      <option value="0">選択してください</option>
      <template v-for="(option, index) of list.Choices">
        <option :value="option.id" :key="index">
          {{ option.label }}
        </option>
      </template>
    </select>
  </div>
</template>

<script>
export default {
  name: "InputSelect",
  props: ["list"],
  methods: {
    updateValue: function (e) {
      this.$emit("input", e.target.value);
    },
  },
};
</script>

<style scoped lang="scss">
.select_wrap {
  width: 100%;
  margin-bottom: 14px;
  position: relative;
  select {
    width: 100%;
    border: 1px solid #dededf;
    height: 38px;
    border-radius: 5px;
    padding: 0 5px;
    font-weight: 500;
    font-size: 14px;
  }
  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    right: 10px;
    font-family: "Material Icons OutLined";
    content: "\e5c5";
    font-size: 22px;
    color: #707070;
  }
}
</style>
