<template>
  <button :disabled="disabled"  @click="$emit('submit')">
    <img height="24px" v-if="src" :src="src" alt="">
    <div style="font-size:18px">{{ text }}</div>
  </button>
</template>

<script>
export default {
  props: ["text","src","disabled"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button {
  height: 30px;
  display: block;
  padding: 5px 20px;
  color: #fff;
  background-color: #f3c526;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0.1em;
  font-weight: 500;
  &:disabled{
    background-color: #ccc;
  }
}
</style>
