<template>
  <div class="back">
    <Blur :status="flag" v-on:close="$emit('close')" />
    <div class="modal" id="modal" v-if="flag">
      <div class="scroll">
        <div class="modal_wrap">
          <slot></slot>
        </div>
      </div>

      <p class="close_bottun" @click="$emit('close')">
        <span class="material-symbols-outlined"> close </span>
      </p>
    </div>
  </div>
</template>

<script>
import Mixin from "../mixins/globalMethods.js";
export default {
  name: "modal-wrap",
  props: ["flag"],
  mixins: [Mixin],
};
</script>
<style lang="scss" scoped>
.modal {
  width: 90%;
  max-width: 810px;
  position: fixed;
  z-index: 4000;
  max-height: 95vh;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;

  .scroll {
    //overflow-y: scroll;
    max-height: 90vh;
    .modal_wrap {
      width: 100%;
      height: 100%;
      //overflow: hidden;
    }
  }
  .close_bottun {
    position: fixed;
    top: -30px;
    right: 5px;
    span {
      font-size: 30px;
      color: #ffffff;
    }
  }
}

>>> .blur {
  z-index: 1000;
}
</style>
