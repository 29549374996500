import { login, logout, getInfo } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import axios from "axios";
import request from "@/utils/request";
const getDefaultState = () => {
  return {
    token: getToken(),
    profile: null,
    drinkStatus: null,
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_PROFILE: (state, profile) => {
    state.profile = profile;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_DRINK_STATUS: (state, drinkStatus) => {
    state.drinkStatus = drinkStatus;
  },
};

const actions = {
  // user login
  async login({ dispatch, commit }, userInfo) {
    await axios
      .get(process.env.VUE_APP_SANCTUM_API, { withCredentials: true })
      .then(() => {
        return new Promise((resolve, reject) => {
          login(userInfo)
            .then((response) => {
              commit("SET_PROFILE", response.user);
              commit("SET_TOKEN", response.user.token);
              setToken(response.user.token);
              dispatch("getDrinkStatus");
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
  },
  async loginByToken({ dispatch, commit }, token) {
    commit("SET_TOKEN", token);
    setToken(token);
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          if (!response) {
            removeToken();
            return reject("Verification failed, please Login again.");
          }
          commit("SET_PROFILE", response);
          dispatch("getDrinkStatus");
          resolve(response.name);
        })
        .catch((error) => {
          removeToken();
          reject(error);
        });
    });
  },

  getDrinkStatus({ commit }) {
    return new Promise((resolve, reject) => {
      request({
        url: "getDrinkStatus",
        method: "get",
      })
        .then((response) => {
          commit("SET_DRINK_STATUS", response);
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  // get user info
  getInfo({ dispatch, commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          if (!response) {
            removeToken();
            return reject("Verification failed, please Login again.");
          }
          commit("SET_PROFILE", response);
          dispatch("getDrinkStatus");
          resolve(response);
        })
        .catch((error) => {
          removeToken();
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          removeToken(); // must remove  token  first
          // resetRouter()
          commit("RESET_STATE");
          resolve();
        })
        .catch((error) => {
          removeToken();
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
