<template>
  <div class="input_wrap">
    <span v-if="status">{{ errorMessages }}</span>
    <input
      type="text"
      @input="$emit('input', $event.target.value)"
      :value="value"
      :placeholder="placeHoler"
    />
  </div>
</template>

<script>
export default {
  name: "InputText",
  props: ["value", "status", "errorMessages", "placeHoler"],
};
</script>

<style scoped lang="scss">
.input_wrap {
  width: 100%;
  margin-bottom: 20px;
  input {
    width: 100%;
    border: 1px solid #dededf;
    height: 38px;
    border-radius: 5px;
    padding: 0 5px;
    font-weight: 500;
    ::placeholder {
      color: orange;
    }
  }
}
.code_wrap {
  input::placeholder {
    text-align: center !important;
  }
}
</style>
