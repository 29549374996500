<template>
  <div id="starting_point">
    <header>
      <div class="left">
        <ul class="" id="hamburger" @click="hamburger()">
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div style="flex-grow: 10; text-align: center">
        <router-link to="/"><img src="@/assets/img/common/logo03.png" alt="" class="logo" />
        </router-link>
      </div>
      <div class="remainingNumber-wrap" v-if="profile">
        <span class="remainingNumber"><img v-if="drinkStatus" :src="
              require(`@/assets/img/ticket/ticket0${drinkStatus.remainingNumber}.png`)
            " alt="" style="width: 60px" /></span>
      </div>

      <router-link to="/login" v-else>
        <img src="@/assets/img/common/icon02.png" alt="" class="icon" />
      </router-link>
    </header>
    <main>
      <router-view @openSearch="searchModal()"></router-view>
    </main>
    <div class="hamburger_menu" id="hamburger_menu">
      <div class="container">
        <div class="head_wrap">
          <div class="left">
            <ul class="-active" id="hamburger" @click="hamburger()">
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <img src="@/assets/img/common/logo01.png" alt="" class="logo" />
        </div>
        <div class="wrap">
          <div class="top">
            <img src="@/assets/img/common/logo02.png" alt="" class="logo" />
            <ul v-if="profile">
              <li>マイページ<router-link to="/my-page"></router-link>
              </li>
              <!-- <li>バズパスの使い方<router-link to="/whats-buzz-pass"></router-link></li> -->
              <li>
                お気に入り<router-link to="/my-page/favorite-restaurant"></router-link>
              </li>
              <li>利用履歴<router-link to="/my-page/history"></router-link>
              </li>
              <li>位置情報の設定方法<router-link to="/locationSetting" /> 
              </li>
            </ul>
            <ul v-else>
              <li>バズパスとは?<a href="https://lp.buzz-pass.com"></a></li>
              <li>ログイン<router-link to="/login"></router-link>
              </li>
              
              <li>会員登録<router-link to="/register"></router-link>
              </li>
              <li @click="searchMovie">動画掲載店舗</li>
              <li>位置情報の設定方法<router-link to="/locationSetting" /> 
              </li>
            </ul>
          </div>
          <div class="bottom">
            <ul>
              <li>
                お問合せ<a href="https://forms.gle/ExBbXFbboiJXKxXBA"></a>
              </li>
              <li>
                加盟店になる<a href="https://lp.buzz-pass.com/lp_shop/index.html"></a>
              </li>
              <li>
                利用規約<router-link to="/special-commercial"></router-link>
              </li>
              <!-- <li>運営会社<router-link to="/"></router-link></li> -->
              <li>
                個人情報保護方針<router-link to="/privacy-policy"></router-link>
              </li>
              <li v-if="profile">
                退会する<router-link to="/my-page/unsubscribe"></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <section class="bottom_menu">
      <ul>
        <li>
          <router-link to="/"><img src="@/assets/img/menu/img01.svg" alt="" /></router-link>
        </li>
        <li @click="searchModal()">
          <img src="@/assets/img/menu/img02.svg" alt="" />
        </li>
        <li>
          <router-link to="/my-page/favorite-restaurant"><img src="@/assets/img/menu/img05.svg" alt="" /></router-link>
        </li>
        <li @click="searchMovie()">
          <img src="@/assets/img/menu/img03.svg" alt="" />
        </li>
        <li>
          <router-link to="/my-page"><img src="@/assets/img/menu/img04.svg" alt="" /></router-link>
        </li>
      </ul>
    </section>
    <footer>
      <img src="@/assets/img/common/logo03.png" alt="" />
      <ul>
        <li>
          <a href="https://forms.gle/ExBbXFbboiJXKxXBA">お問い合わせ</a>
        </li>
        <li>
          <a href="https://lp.buzz-pass.com/lp_shop/index.html">加盟店になる</a>
        </li>
      </ul>
      <ul>
        <li>
          <router-link to="/special-commercial">利用規約</router-link>
        </li>
        <!-- <li><router-link to="/">運営会社</router-link></li> -->
        <li>
          <router-link to="/privacy-policy">個人情報保護方針</router-link>
        </li>
      </ul>
    </footer>
    <Blur :status="status" v-on:close="hamburger()" />
    <Modal :flag="searchFlag" v-on:close="closeModal()" :class="'main_modal'">
      <p class="ttl">検索</p>
      <label class="common_label">ディナー利用条件</label>
      <InputSelect :list="formData.use_price.list" v-model.number="params.use_price_dinner"
        :status="formData.cat.status" :message="formData.cat.errorMessages"></InputSelect>
      <label class="common_label">ランチ利用条件</label>
      <InputSelect :list="formData.use_price.list" v-model.number="params.use_price_lunch" :status="formData.cat.status"
        :message="formData.cat.errorMessages"></InputSelect>
      <label class="common_label">店舗名</label>
      <InputText v-model="params.text" :value="params.text" :status="formData.name.status"
        :message="formData.name.errorMessages"></InputText>
      <label class="common_label">フリーワード</label>
      <InputText v-model="params.free_text" :value="params.free_text" :status="formData.name.status"
        :message="formData.name.errorMessages"></InputText>

      <label class="common_label">ジャンル</label>
      <InputSelect :list="formData.cat.list" v-model="params.category_id" :status="formData.cat.status"
        :message="formData.cat.errorMessages"></InputSelect>

      <div style="margin-bottom: 20px">
        <label class="common_label">駅</label>
        <remoteSelect v-model="params.station_id" :value="params.station_id"
          :meta="{ placeholder: '入力で検索', api: 'stationSelectValue' }" />
      </div>

      <!-- <div v-if="params.place == 3" class="staion_wrap">

        <label class="common_label">都道府県</label>
        <InputSelect
          :list="formData.prefecture.list"
          v-model="prefecture"
          :status="formData.prefecture.status"
          :message="formData.prefecture.errorMessages"
        ></InputSelect>
        <label class="common_label">駅名</label>
        <InputSelect
          :list="formData.station.list"
          v-model="params.station_id"
          :status="formData.station.status"
          :message="formData.station.errorMessages"
        ></InputSelect>
      </div> -->
      <div class="right">
        <NextBtn style="width: 100%; height: 36px" :disabled="!loaded" :text="'検索'" @submit="search()" />
      </div>
    </Modal>
  </div>
</template>
<script>
  // import Blur from "../utilComponents/blur.vue";
  import Mixin from "../mixins/globalMethods.js";

  import "vue-select/dist/vue-select.css";
  import Modal from "@/components/modal.vue";
  import InputText from "@/components/Input/text.vue";
  //import Radio from "@/components/Input/radio.vue";
  //import InputCheck from "@/components/Input/check.vue";
  import InputSelect from "@/components/Input/select.vue";
  import remoteSelect from "@/components/Input/remoteSelect.vue";
  import NextBtn from "@/components/Button/nextButton.vue";
  import request from "@/utils/request";
  export default {
    name: "Main-page",
    mixins: [Mixin],
    components: {
      Modal: Modal,
      remoteSelect: remoteSelect,
      InputText: InputText,
      //  Radio: Radio,
      InputSelect: InputSelect,
      NextBtn: NextBtn,
    },
    data() {
      return {
        formData: {
          name: {
            status: false,
            errorMessages: null,
          },
          place: {
            status: false,
            errorMessages: null,
            list: {
              name: "place",
              Choices: [{
                  label: "指定しない",
                  id: 1,
                },
                {
                  label: "近くの店舗",
                  disabled: true,
                  id: 2,
                },
                {
                  label: "駅を選択する",
                  id: 3,
                },
              ],
            },
          },
          cat: {
            status: false,
            errorMessages: null,
            list: {
              name: "cat",
              Choices: [],
            },
          },
          use_price: {
            status: false,
            errorMessages: null,
            list: {
              name: "cat",
              Choices: [{
                  label: "¥1,000以下",
                  id: 1000
                },
                {
                  label: "¥2,000以下",
                  id: 2000
                },
                {
                  label: "¥3,000以下",
                  id: 3000
                },
                {
                  label: "¥5,000以下",
                  id: 5000
                },
                {
                  label: "¥10,000以下",
                  id: 10000
                },
              ],
            },
          },
          check: {
            status: false,
            errorMessages: null,
          },
          prefecture: {
            status: false,
            errorMessages: null,
            list: {
              name: "prefecture",
              Choices: [],
            },
          },
          station: {
            status: false,
            errorMessages: null,
            list: {
              name: "station",
              Choices: [],
            },
          },
        },
        params: {
          text: null,
          free_text: null,
          place: 3,
          use_price_lunch: null,
          use_price_dinner: null,
          has_movie: false,
          category_id: null,
          station_id: null,
          lat: null,
          lon: null,
        },
        loaded: false,
        prefecture: null,
        searchFlag: false,
        locationUnusefull: true,
      };
    },

    methods: {
      hamburger() {
        var target = document.getElementById("hamburger");
        if (target.className == "-active") {
          target.classList.remove("-active");
          document
            .getElementById("hamburger_menu")
            .classList.remove("hamburger-active");
          this.noneBlur();
        } else {
          target.classList.add("-active");
          document
            .getElementById("hamburger_menu")
            .classList.add("hamburger-active");
          this.displayBlur();
        }
      },
      searchModal() {
        this.searchFlag = true;
      },
      closeModal() {
        this.searchFlag = false;
        this.params = {
          ...this.params,
          ...{
            free_text: null,
            text: null,
            use_price_lunch: null,
            use_price_dinner: null,
            has_movie: false,
            category_id: null,
            station_id: null,
          },
        };
      },
      searchMovie() {
        let Mparams = {
          has_movie: true,
          lat: this.params.lat,
          lon: this.params.lon,
          title: "動画掲載店一覧",
        };
        let vm = this;
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              let coords = position.coords;
              // 緯度経度だけ取得
              Mparams.lat = coords.latitude;
              Mparams.lon = coords.longitude;
              vm.$router.push({
                name: "RestauranList",
                query: Mparams,
              });
              vm.closeModal();
            }.bind(this),
            function () {
              vm.$router.push({
                name: "RestauranList",
                query: Mparams,
              });
              vm.closeModal();
            }
          );
        } else {
          this.$router.push({
            name: "RestauranList",
            query: Mparams,
          });
          this.closeModal();
        }
      },
      getCat() {
        request({
            url: "searchSelectValue",
            method: "get",
          })
          .then((response) => {
            const array = response.categories.map((value) => {
              return {
                id: value.id,
                label: value.name,
              };
            });
            this.formData.cat.list.Choices = array;
            if (response.prefectures) {
              this.formData.prefecture.list.Choices = response.prefectures.map(
                (value) => {
                  return {
                    id: value.prefectures,
                    label: value.prefectures,
                  };
                }
              );
            }
          })
          .catch(() => {});
      },
      init() {
        this.getCat();
      },
      search() {
        let searchData = {};
        console.log(this.params);
        for (let k of Object.keys(this.params)) {
          if (this.params[k] != null && this.params[k] != "") {
            searchData[k] = this.params[k];
          }
        }
        this.$router.push({
          name: "RestauranList",
          query: searchData,
        });
        this.closeModal();
      },
    },
    created() {
      this.init();
    },
    computed: {
      profile() {
        return this.$store.getters.profile;
      },
      drinkStatus() {
        return this.$store.getters.drinkStatus;
      },
    },
    watch: {
      $route() {
        var target = document.getElementById("hamburger");
        if (target.className == "-active") {
          this.hamburger();
        }
      },
      prefecture() {
        request({
            url: "stationListByPrefectures/" + this.prefecture,
            method: "get",
          })
          .then((response) => {
            const array = response.map((value) => {
              return {
                id: value.id,
                label: value.display_name,
              };
            });
            this.formData.station.list.Choices = array;
          })
          .catch((e) => {
            console.log(e);
          });
      },
    },
    mounted() {
      if (navigator.geolocation) {
        let vm = this;
        navigator.geolocation.getCurrentPosition(
          function (position) {
            let coords = position.coords;
            // 緯度経度だけ取得
            vm.params.lat = coords.latitude;
            vm.params.lon = coords.longitude;
            vm.$set(vm.formData.place.list.Choices[1], "disabled", false);
            vm.loaded = true;
          }.bind(this),
          function () {
            vm.loaded = true;
          }
        );
      }
    },
  };
</script>
<style lang="scss" scoped>
  .remainingNumber-wrap {
    //width: 30px;
    position: relative;

    .remainingNumber {
      //position: absolute;
      right: 0;
      font-size: 12px;
      display: inline-block;
      padding: 10px;
      border-radius: 10px;
      background-color: #fff;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 15px;
        left: -20px;
        border: 8px solid transparent;
        border-right: 20px solid #fff;
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
      }
    }
  }
</style>
<style lang="scss">
  #app {
    max-width: 900px;
    margin: 0 auto;
  }

  header {
    max-width: 900px;
    height: 60px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3c526;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    width: 100%;
    position: fixed;
    z-index: 50;

    .logo {
      width: 130px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }

    .icon {
      width: 42px;
    }

    .left {
      width: 30px;
      height: 18px;

      ul {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        li {
          height: 2px;
          border-radius: 30px;
          background-color: #fff;
          width: 100%;
          transition: 0.2s;
          transform-origin: left top;
        }
      }

      .-active {
        li {
          &:nth-child(1) {
            transform: rotate(27deg);
            width: 116%;
          }

          &:nth-child(2) {
            display: none;
          }

          &:nth-child(3) {
            transform: rotate(-27deg);
            width: 113%;
          }
        }
      }
    }
  }

  main {
    margin-top: 60px;
    min-height: calc(100vh - (461px));
  }

  .hamburger_menu {
    max-width: 720px;
    position: fixed;
    top: 0px;
    z-index: 80;
    left: -100%;
    width: 80vw;
    height: 100vh;
    transition: 0.2s;

    .container {
      height: 100%;
      width: 100%;
      position: relative;
      background-color: #f3c526;

      .head_wrap {
        display: flex;
        padding: 0 20px;
        align-items: center;
        height: 60px;

        .left {
          width: 30px;
          height: 18px;

          ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;

            li {
              height: 2px;
              border-radius: 30px;
              background-color: #fff;
              width: 100%;
              transition: 0.2s;
              transform-origin: left top;
            }
          }

          .-active {
            li {
              &:nth-child(1) {
                transform: rotate(27deg);
                width: 116%;
              }

              &:nth-child(2) {
                display: none;
              }

              &:nth-child(3) {
                transform: rotate(-27deg);
                width: 113%;
              }
            }
          }
        }

        .logo {
          width: 130px;
          margin-left: 50px;
        }
      }

      .back {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 39;
        background-color: #000000;
        opacity: 0.8;
        transition: 0.2s;
      }

      .wrap {
        transition: 0.2s;
        background-color: #fff;
        height: calc(100% - 60px);
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
          width: 155px;
          display: block;
          margin: 0 auto;
        }

        li {
          position: relative;
          transition: 0.2s;

          &:hover {
            letter-spacing: 0.3em;
            border-bottom: #f3c526;
            color: #f3c526;
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        .top {
          ul {
            margin-top: 20px;
          }
        }

        .bottom {
          font-size: 14px;
          border-top: 1px solid #707070;
          padding-top: 20px;
          padding-bottom: 20px;

          li {
            text-align: center;
          }
        }
      }
    }
  }

  .hamburger-active {
    left: calc((100% - (900px)) / 2);

    @media screen and (max-width: 900px) {
      left: 0;
    }
  }

  .bottom_menu {
    padding: 10px 20px 10px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    width: 100%;
    position: fixed;
    z-index: 50;
    max-width: 900px;
    background: #f3c526;

    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;

      li {
        width: 55px;

        img {
          height: 38px;
          margin: 0 auto;
          display: block;
        }
      }
    }
  }

  .common_border {
    border: 4px solid #f3c526;
    border-radius: 12px;
  }

  footer {
    background: #f3c526;
    padding: 20px 20px 100px;
    margin-top: 100px;

    img {
      width: 154px;
      margin: 0 auto 20px;
      display: block;
    }

    ul {
      display: flex;
      justify-content: center;
      gap: 12px 12px;
      margin-bottom: 10px;

      li {
        margin-right: 10px;

        a {
          color: #fff;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }

  .main_modal {
    .ttl {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .common_label {
      color: #333333;
      font-size: 14px;
    }

    .right {
      margin-top: 20px;
      display: flex;
      justify-content: end;
    }
  }
</style>