import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../layouts/main.vue";
import { getToken } from "@/utils/auth";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MainRoute",
    component: Main,
    children: [
      {
        path: "locationSetting",
        name: "locationSetting",
        index: 1,
        component: () => import("@/views/WhatsBuzzPass/locationSetting"),
      },
      {
        path: "",
        name: "home",
        index: 1,
        component: () => import("@/views/TopPage"),
      },
      {
        path: "/login",
        name: "loginPage",
        index: 2,
        component: () => import("@/views/Auth/Login"),
        beforeEnter(to, from, next) {
          if (getToken()) {
            next("/my-page");
          } else {
            next(next);
          }
        },
      },
      {
        path: "/restaurant/:id",
        name: "restaurantDetail",
        index: 3,
        component: () => import("@/views/Restaurant/Detail"),
      },
      {
        path: "/restauranList",
        name: "RestauranList",
        props: true,
        index: 9,
        component: () => import("@/views/Restaurant/SearchResult"),
      },
      {
        path: "/login",
        name: "Login",
        index: 3,
        component: () => import("@/views/Auth/Login"),
      },
      {
        path: "/forget-password",
        name: "passwordReset",
        index: 4,
        component: () => import("@/views/Auth/ForgetPassword"),
      },
      {
        path: "/password-reset/:token",
        name: "passwordResetForm",
        index: 5,
        component: () => import("@/views/Auth/PasswordResetForm"),
      },
      {//会員仮登録方法選択画面
        path: "/register",
        name: "register",
        index: 6,
        component: () => import("@/views/Auth/RegisterTypeSelect"),
      },
      {//会員仮登録画面
        path: "/register-email",
        name: "register-email",
        index: 6,
        component: () => import("@/views/Auth/RegisterEmail"),
      },
      {//会員仮登録画面
        path: "/register-phone",
        name: "register-phone",
        index: 6,
        component: () => import("@/views/Auth/RegisterPhone"),
      },
      {//会員登録画面
        path: "/register-form/:token",
        name: "registerForm",
        index: 7,
        component: () => import("@/views/Auth/RegisterForm"),
      },
      {//メールアドレス変更完了画面
        path: "/complete-email-change/:token",
        name: "completeEmailChange",
        index: 3,
        component: () => import("@/views/Auth/CompleteEmailChange"),
      },
       {//メールアドレス変更完了画面
        path: "/complete-sbps/:token",
        name: "CompleteSbps",
        index: 3,
        component: () => import("@/views/Auth/CompleteSbps"),
      },
      {
        path: "/payment-subscription",
        name: "paymentSubscription",
        index: 8,
        component: () => import("@/views/Auth/PaymentSubscription"),
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        index: 9,
        component: () => import("@/views/Law/PrivacyPolicy"),
      },
      {
        path: "/special-commercial",
        name: "SpecialCommercial ",
        index: 10,
        component: () => import("@/views/Law/SpecialCommercial"),
      },
    ],
  },

  {
    path: "/my-page",
    name: "MYpageMain",
    component: Main,
    children: [
      {
        path: "/",
        name: "Mypage",
        index: 11,
        component: () => import("@/views/MyPage/TopPage"),
      },
      {
        path: "favorite-restaurant",
        name: "shopFavorite",
        index: 10,
        component: () => import("@/views/MyPage/FavoriteRestaurantList"),
      },
      {
        path: "edit-profile",
        name: "Edit",
        index: 12,
        component: () => import("@/views/MyPage/EditProfile"),
      },
      {
        path: "unsubscribe",
        name: "unsubscribe",
        index: 13,
        component: () => import("@/views/MyPage/Unsubscribe"),
      },
      {
        path: "history",
        name: "History",
        index: 14,
        component: () => import("@/views/MyPage/CouponUseHistory"),
      },
    ],
    beforeEnter(to, from, next) {
      if (getToken()) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/whats-buzz-pass",
    name: "about",
    redirect: '/'
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
